<template>
  <div class="page bg-white" id="reorganize">
    <div class="pageHeight">
      <el-steps v-if="butType == 0" :active="step" align-center style="margin-bottom: 20px">
        <el-step title="基本信息"></el-step>
        <el-step title="绑定藏品"></el-step>
        <el-step title="预览"></el-step>
      </el-steps>

      <div v-show="step == 0 || step == 2 || step == 99">
        <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="200px"
                 size="small">
          <el-row>
            <el-col :span="22">
              <el-form-item label="文献" prop="docuFile">
                <resume-upload :dir="'collection'" ref="resumeUpload" :filtButton="filtButton" :allowFiles="allowFiles"
                               :uploadNum="uploadNum" :upFileSize="upFileSize"
                               @upFileDate="(data)=>getUpData(data)"></resume-upload>
                <div v-for="(item,index) in inputForm.docuFile">
                  <el-card class="box-card">
                    <div class="flex_b_c" style="cursor: pointer">
                      <div class="el-icon-notebook-2" @click="previewPdf(item.netUrl)"></div>
                      <div style="flex: 1;margin: 0 20px" @click="previewPdf(item.netUrl)">
                        <el-tooltip class="item" effect="dark" :content="item.fileName"
                                    placement="top-start">
                          <div class="omit">{{ item.fileName }}</div>
                        </el-tooltip>
                      </div>
                      <div class="el-icon-circle-close" v-show="!inputType"
                           style="cursor: pointer"
                           @click="deteliFile(item,index)"></div>
                    </div>
                  </el-card>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="文献名称" prop="docuName">
                <el-input
                    v-model.trim="inputForm.docuName"
                    placeholder="请输入文献名称(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="作者姓名" prop="docuAuthor">
                <el-input
                    v-model.trim="inputForm.docuAuthor"
                    placeholder="请输入作者姓名(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="成文时间" prop="writtenDate">
                <el-date-picker
                    style="width: 100%"
                    v-model="inputForm.writtenDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :disabled="inputType"
                    clearable
                    placeholder="请选择成文时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="导师姓名" prop="tutorName">
                <el-input
                    v-model.trim="inputForm.tutorName"
                    placeholder="请输入导师姓名(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="出版地" prop="publishLand">
                <el-input
                    v-model.trim="inputForm.publishLand"
                    placeholder="请输入出版地(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="出版年" prop="publishDate">
                <el-date-picker
                    style="width: 100%"
                    v-model="inputForm.publishDate"
                    type="year"
                    value-format="yyyy"
                    :disabled="inputType"
                    clearable
                    placeholder="请选择出版年">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="出版者" prop="publishThose">
                <el-input
                    v-model.trim="inputForm.publishThose"
                    placeholder="请输入出版者(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="页码" prop="pagination">
                <el-input
                    v-model.trim="inputForm.pagination"
                    placeholder="请输入页码(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="关键词" prop="keyword">
                <el-tag
                    :key="tag"
                    size="medium"
                    v-for="tag in inputForm.keyword"
                    :closable="!inputType"
                    :disable-transitions="false"
                    @close="handleClose(tag)">
                  {{ tag }}
                </el-tag>
                <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model.trim="inputValue"
                    ref="saveTagInput"
                    size="small"
                    placeholder="请输关键词(限30字)"
                    maxlength="30"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                >
                </el-input>
                <el-button v-else :disabled="inputForm.keyword.length == 20 || inputType"
                           class="button-new-tag"
                           size="small" @click="showInput">+ 新增关键词
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="摘要" prop="digest">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.digest" maxlength="200"
                          placeholder="请输入摘要(限200字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="相关链接" prop="link">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.link" maxlength="500"
                          placeholder="请输入文献相关链接(限500字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.remark" maxlength="100"
                          placeholder="其它需要特别说明的事项或信息(限100字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div v-show="step == 1 || step == 2">
        <el-button size="small" v-if="!inputType" type="primary" @click="addCollData(0)"
                   icon="el-icon-plus">
          添加藏品
        </el-button>
        <div class="flex_b_c">
          <div>
            <div>
              <div class="minTitle">藏品清单</div>
              <div style="margin-left: 20px">已选{{ dataList.length }} 件藏品</div>
            </div>
          </div>
          <el-button icon="el-icon-delete" v-show="!inputType" type="danger" size="small" @click="deleteCollData()">
            批量删除
          </el-button>
          <el-button v-if="butType == 1" size="small" @click="exportData()">
            <i class="icon-piliangdaochu iconfont buIcon"/>
            批量导出
          </el-button>
        </div>
        <el-table
            :data="dataList"
            size="small"
            ref="multipleTable"
            style="padding-left: 20px"
            height="calc(100vh - 395px)"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>


    <div class="text_center" style="margin-top: 10px" v-if="step == 0">
      <el-button size="small" @click="returnPage(0)">取消</el-button>
      <el-button size="small" type="primary" @click="nextStep(0)">下一步</el-button>
    </div>
    <div class="text_center" v-if="step == 1">
      <el-button size="small" @click="returnPage(1)">取消</el-button>
      <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
      <el-button size="small" type="primary" @click="nextStep(0)">预览</el-button>
    </div>

    <div v-if="step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="butType == 0 || butType == 2">
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <!--                <el-button size="small" type="primary" @click="saveAsDraft(0)">保存为草稿</el-button>-->
        <el-button size="small" type="primary" @click="saveAsDraft(1)">提交</el-button>
      </div>
      <div class="text_center" v-else>
        <!--                <el-button v-if="butType == 4" size="small" type="primary" @click="switchData(1)">上一条</el-button>-->
        <!--                <el-button v-if="butType == 4" size="small" type="primary" @click="switchData(0)">下一条</el-button>-->
        <el-button size="small" @click="returnPage(1)">关闭</el-button>
        <!--                <el-button size="small" v-if="butType == 4 && this.inputForm.auditStatus == 1" type="primary"-->
        <!--                           @click="through(3)">通过-->
        <!--                </el-button>-->
        <!--                <el-button size="small" v-if="butType == 4 && this.inputForm.auditStatus == 1" type="danger"-->
        <!--                           @click="through(2)">驳回-->
        <!--                </el-button>-->
      </div>
    </div>
    <div class="text_center" v-if="step == 99">
      <el-button size="small" @click="returnPage(1)">关闭</el-button>
    </div>
    <collection-list ref="collectionList" @seltDataColl="(data)=>this.dataList.push(...data)"></collection-list>
  </div>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";
import {Base64} from 'js-base64'
import CollectionList from "@/views/modules/collection/business/module/collectionList.vue";
import {push} from "echarts/lib/component/dataZoom/history";

export default {
  name: "literatureDetail",
  components: {CollectionList, ResumeUpload},
  data() {
    return {
      appraisalId: '',
      step: 0,
      inputForm: {
        docuFile: [],
        docuName: '',
        docuAuthor: '',
        writtenDate: '',
        tutorName: '',
        publishLand: '',
        publishDate: '',
        publishThose: '',
        pagination: '',
        keyword: [],
        digest: '',
        link: '',
        remark: '',
      },
      rules: {
        docuFile: [
          {type: 'array', required: true, message: "请上传文献资料", trigger: "blur"},
        ],
        docuName: [
          {required: true, message: "请输入文献名称", trigger: "blur"},
        ],
      },

      //文件上传
      uploadNum: 1,
      upFileSize: 30,
      allowFiles: ['PDF'],
      filtButton: true,

      //关键词
      inputVisible: false,
      inputValue: '',

      //藏品
      dataList: [],
      dataListSelect: [],
      leaveList: [],

      //上一条下一条
      registrationSearch: {},

      inputType: false, //按钮是否禁用
      returnPageStatus: '', //返回
      butType: "", //0新增 1详情 2修改 4审核
      sourceType:'',//来源类型（0 典藏 ，1 数字资源）
    }
  },

  mounted() {
    this.docuId = this.$route.query.docuId
    this.butType = this.$route.query.butType
    if (this.butType == 1) {
      this.inputType = true
      this.step = 2
    }
    if (this.butType == 5) {
      this.inputType = true
      this.step = 99
      this.sourceType = this.$route.query.sourceType
    }
    if (this.docuId) {
      if (this.butType != 5){
        this.getRowDetail()
      }else {
        this.getCollRowDetail()
      }
    }
    this.setPageTitle()

    //藏品等级查询
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
  },

  methods: {
    //获取详情
    getRowDetail() {
      this.$axios(this.api.collection.docuById + this.docuId, {}, 'get').then(data => {
        if (data.status) {
          this.inputForm = data.data
          if (this.inputForm.keyword) {
            this.inputForm.keyword = data.data.keyword.split(',')
          } else {
            this.inputForm.keyword = []
          }
          let docuFile = [{
            fileName: data.data.docuName,
            netUrl: data.data.netUrl,
            id: data.data.docuId,
            fileSize: data.data.fileSize,
          }]
          this.$set(this.inputForm, 'docuFile', docuFile)
          this.$refs['resumeUpload'].setOldFile(this.inputForm.docuFile)
          this.dataList = data.data.dataList
        }
      })
    },

    //藏品登记文献详情接口
    getCollRowDetail(){
      this.$axios(this.api.collection.docuGetInfoById, {
        docuId:this.docuId,
        sourceType:this.sourceType,
      }, 'get').then(data => {
        if (data.status) {
          this.inputForm = data.data
          if (this.inputForm.keyword) {
            this.inputForm.keyword = data.data.keyword.split(',')
          } else {
            this.inputForm.keyword = []
          }
          let docuFile = [{
            fileName: data.data.docuName,
            netUrl: data.data.netUrl,
            id: data.data.docuId,
            fileSize: data.data.fileSize,
          }]
          this.$set(this.inputForm, 'docuFile', docuFile)
          this.$refs['resumeUpload'].setOldFile(this.inputForm.docuFile)
          this.dataList = data.data.dataList
        }
      })
    },

    getUpData(data) {
      this.inputForm.docuFile = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
      // if (this.inputForm.docuFile.length != 0 && !this.inputForm.docuName) {
      //     this.inputForm.docuName = this.inputForm.docuFile[0].fileName
      // }
    },
    //pdf预览
    previewPdf(url) {
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(url)));
    },

    //删除文件
    deteliFile(row, index) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.inputForm.docuFile.splice(index, 1)
        this.$refs["resumeUpload"].deleteFile(row.fileID ? row.fileID : row.id)
      }).catch(() => {
      })
    },

    handleClose(tag) {
      this.$confirm(`您是否确认删除该关键词?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.inputForm.keyword.splice(this.inputForm.keyword.indexOf(tag), 1);
      })
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.keyword.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      let reapet = this.inputForm.keyword.filter(item => {
        return item == inputValue
      })
      if (reapet.length != 0) {
        this.$message.error('您已经添加过该关键词了')
        this.inputVisible = false;
        this.inputValue = '';
        return
      }
      if (inputValue) {
        this.inputForm.keyword.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    setPageTitle() {
      let name = '文献详情'
      if (this.butType == 0) {
        name = '文献新增'
      } else if (this.butType == 2) {
        name = '文献修改'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    //添加藏品
    addCollData() {
      this.$refs.collectionList.int(this.dataList)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    deleteCollData() {
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.$confirm(`您是否确认删除选中藏品?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListSelect.forEach(item1 => {
          this.dataList = this.dataList.filter(item2 => {
            return item1.id != item2.id
          })
        })
        this.$refs.multipleTable.clearSelection()
      })
    },

    //0下一步 1上一步
    nextStep(num) {
      if (num == 0) {
        if (this.step == 0) {
          this.$refs.inputForm.validate((valid) => {
            if (valid) {
              this.step++;
            }
          })
        } else {
          if (this.step == 1) {
            if (this.dataList.length == 0) {
              this.$message.error('请至少选择一件藏品')
              return
            }
            this.step++;
            this.inputType = true
          }
        }
      } else {
        if (this.step == 2) {
          this.inputType = false
        }
        this.step--;
      }
    },

    //导出
    exportData() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.id
        }
      })
      this.exportExcel(this.api.collection.collectionExport, ids, '文献藏品清单', 'post')
      this.$refs.multipleTable.clearSelection()
    },

    // type 1提交  0保存为草稿
    saveAsDraft(type) {
      this.dataList.forEach(item => {
        this.$set(item, 'dataId', item.id)
      })
      let fechUrl = this.api.collection.docuSave
      let methods = 'post'
      if (this.docuId) {
        fechUrl = this.api.collection.docuUpdateById
        methods = 'put'
      }
      this.$axios(fechUrl, {
        ...this.inputForm,
        netUrl: this.inputForm.docuFile[0].filePath,
        fileSize: this.inputForm.docuFile[0].fileSize,
        filePath: this.inputForm.docuFile[0].filePath,
        keyword: this.inputForm.keyword.join(','),
        collections: this.dataList,
      }, methods).then(data => {
        if (data.status) {
          this.$message.success(`提交成功！`)
          this.programExit = true
          this.returnPage()
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    //type 2驳回 3通过
    through(type) {
      // this.$set(this.inputForm, 'id', this.appraisalId)
      // this.$refs.suggestions.init(2, [this.inputForm], type, revdId, this.api.collection.collectionappraisalBatchExamine)
    },

    //1为上一条 0为下一条
    switchData(num) {
      //     if (Object.keys(this.registrationSearch).length == 0) {
      //         this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      //     }
      //     if (num == 0) {
      //         this.registrationSearch.current++
      //     } else {
      //         if (this.registrationSearch.current = 1) {
      //             this.$message.error('已是第一条')
      //         } else {
      //             this.registrationSearch.current--
      //         }
      //     }
      //     this.$axios(this.api.collection.collectionappraisalList, this.registrationSearch, 'get').then((res) => {
      //         if (res.status) {
      //             if (res.data && res.data.records.length) {
      //                 this.appraisalId = res.data[0].appraisalId
      //                 this.getRowDetail(res.data.archivesBasicDataId)
      //             } else {
      //                 this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
      //             }
      //         } else {
      //             this.$message.error(res.msg);
      //         }
      //     })
    },
  },


  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
      sessionStorage.removeItem('listSearchCollDetail')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
      next();
    } else if (this.butType == 0 || this.butType == 2) {
      this.$confirm("当前内容没有保存，您确定离开吗？", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            if (this.returnPageStatus){
              this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
            }
            //进去别的页面
            next();
          })
          .catch(() => {
            next(false);
          });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}

.box-card ::v-deep .el-card__body {
  padding: 10px !important;
}

.input-new-tag {
  width: 200px;
}

.input-new-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
}

.pageHeight {
  height: calc(100vh - 205px);
  overflow-y: auto;
}
</style>
